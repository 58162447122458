import "./main.css"
import { Elm } from "./Main.elm"
import * as serviceWorker from "./serviceWorker"

const app = Elm.Main.init({
    node: document.getElementById("root"),
    flags: location.href
})

// From tag normal content to copy in <head>
app.ports.triggerAnalytics.subscribe(_ => {
    // For the Market Gardener Analytics
    ;(function (w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != "dataLayer" ? "&l=" + l : ""
        j.async = true
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
        f.parentNode.insertBefore(j, f)
    })(window, document, "script", "dataLayer", "GTM-MXRCCP8")
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
